import '../styles/slo.css';
import SloNavbar from '../components/navbars/SloNavbar';
import * as React from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation} from 'react-i18next';
import Footer from './Footer'
function Slovenia() {

    const form1 = React.useRef();
    const { t, i18n } = useTranslation();
    //i18n.changeLanguage("en");

    const sendEmail = (e) => {
        console.log(process.env.REACT_APP_TITLE);
        e.preventDefault();
        emailjs
          .sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            form1.current,
            process.env.REACT_APP_PUBLIC_KEY
          )
          .then(
            (result) => {
              alert('message sent successfully...');
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
      };
  return (
    <div className="SloveniaContainer">
    <SloNavbar />
    <div className='heroPage'>
        <div className="underHeaderHeroTextSlo"><h3>{t('SloHeroText1')}</h3>
        </div>
        <div className="contentContainer" id="sloContentBg">
          <div className="SloContentInnerContainer">
        <div className="pageText">
        <h1>{t('SloHeroText2')}</h1>
        <p>
        {t('slo-p1')}<br />
        </p>
        <p>
        {t('slo-p2')}<br />
        </p>
        <p>
        {t('slo-p3')}<br />
        </p>
        </div>
        <div className="inquiryPage">
        <div className="inquiryFormContainerSlo">
        <div className="inquiryText">
              <h1>{t('homePovpraševanje1')}</h1>
            </div>
        <div className="inquiryTitle">
          <h3>{t('homePovpraševanje3')}</h3>
        </div>
          <div className="inquiryFormTitle">
            <h2>{t('homeServicesMainTitle2')}</h2>
          </div>
          <div className="inquiryFormInnerContainer">

            <form onSubmit={sendEmail} ref={form1}>
            <span className="radioButtons">
              <span className="radioButtonsLeft">
                <span>
              <input type="radio" id="selitvePoSloveniji" name="radio" defaultValue={t('inquiryGlobal1')} />
              <label htmlFor="selitvePoSloveniji">{t('inquiryGlobal1')}</label><br />
                </span>
              </span>
              <span className="radioButtonsRight">
                <span>
                   <input type="radio" id="mednarodneSelitve" name="radio" defaultValue={t('inquiryGlobal3')} />
                   <label htmlFor="mednarodneSelitve">{t('inquiryGlobal3')}</label><br />
                </span>
              </span>
            </span>
            <span className="lowerHalfOfForm">
              <span className="leftForm">
              <input type="text" id="imePriimek" name="imePriimek" placeholder={t('inquiryGlobal7')} /><br />
              <input type="text" id="email" name="email" placeholder={t('inquiryGlobal8')}/><br />
              <span className="inLineInput">
              <input type="text" id="selimIz" name="selimIz" placeholder={t('inquiryGlobal9')}/>
              <input type="text" id="selimV" name="selimV" placeholder={t('inquiryGlobal10')}/><br />
              </span>
              <span className="selectContainer">
              <select name="roomQuantity" className="formDropdown">
                <option value="" disabled selected>Koliko sob selite</option>
                <option value="1">1 soba</option>
                <option value="2">2 sobi</option>
                <option value="3">3 sobe</option>
                <option value="4">4 sobe</option>
              </select>
              <select name="furnishing" className="formDropdown">
                <option value="" disabled selected>koliko so opremljene sobe</option>
                <option value="1">malo opremljene sobe</option>
                <option value="2">srednje opremljene sobe</option>
                <option value="3">bolj opremljene sobe</option>
              </select>
              </span>
              <input type="text" id="telefon" name="telefon" placeholder={t('inquiryGlobal11')}/><br />
            </span>
            <span className="rightForm">
              <textarea placeholder={t('inquiryGlobal12')} maxLength='1000' minLength='100' rows="16" cols="89">
                
              </textarea>
            </span>
              
            </span>
            <span className="submits">
            <input type="submit" value={t('inquiryGlobal13')} id="firstSubmit" />
            </span>

            </form>

          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
}

export default Slovenia;
