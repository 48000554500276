import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import logo from '../../assets/vatovecLogo.png';
import mlogo from '../../assets/icons/mLogo.svg';
import telefon from '../../assets/icons/2.svg';
import mail from '../../assets/icons/8.svg'
import kombi from '../../assets/icons/kombi.svg';
import eng from '../../assets/icons/eng.svg';
import slo from '../../assets/icons/slo.svg';
import i18next from 'i18next';
import '../../styles/Navbar.css';
import {
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
  Link
} from "react-router-dom";

import { useTranslation, Trans } from 'react-i18next';
const lngs = {
  en: { nativeName: 'English' },
  slo: { nativeName: 'Slovenian' }
};

const pages = ['about', 'services', 'contact'];
const settings = [
  {text: 'navbar-slo',
    url: "/slovenia",
  },
  {text: 'navbar-tujina',
  url: "/abroad",
  },
  {text: 'navbar-sklad',
  url: "/warehousing",
  },
  {text: 'navbar-other',
  url: "/other",
  }];

function SloNavbar() {
  const { t, i18n } = useTranslation();
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <AppBar position="fixed">
      <Container className='OuterContainerSubpageNavbar'>
        <Toolbar disableGutters sx={{ flexWrap: 'no-wrap' }} id="sloToolbar">
        <a href="/">
        <img src={mlogo} className="mobileLogo" id="mobileL" alt="logo"/>
        <img src={logo} className="mobileLogo" id="L" alt="logo"/>
        </a>
          {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none'}}}>
            
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' }
                
              }}
            >
              
              {pages.map((page) => (
                <MenuItem key={page} onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">{page}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}

          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
          >
          </Typography>
          <Box sx={{ flexGrow: 0, marginLeft: 'auto' }}>
          <div className="navbarContactContainerSub">
            <div className="navbarContact">
               <div className="navbarContactImg">
               <img src={telefon} className="navbarCallUsIcon"alt="call now" />
               </div>
               <div className="navbarContactText">
                <h3>{t('navbar2')}</h3>
                <p>{t('navbar3')}</p>

                </div>
            </div>
            <div className="navbarContact">
               <div className="navbarContactImg">
               <img src={mail} className="navbarCallUsIcon"alt="mail now" />
               </div>
               <div className="navbarContactText">
                <h3>{t('navbar4')}</h3>
                <p>{t('navbar5')}</p>

                </div>
            </div>
            <div className="navbarContact" id="burgero">
            <nav role="navigation">
  <div id="menuToggle">

    <input type="checkbox" />
    
    <span></span>
    <span></span>
    <span></span>
    
    <ul id="menu">

    {settings.length>0?              
              settings.map((setting, index) => (
    <a href={setting.url} key={index}><li>{i18next.t(setting.text)}</li></a>
  ))
  :
  <p>Loading...</p>
}
    </ul>
  </div>
</nav>
            </div>
          </div>
          {/* <div className="navbarSubpageLinkMenu">
          {pages.map((page) => (
              <a href={"/" + "#" + page} key={page} >
               <Button href=""
                 key={page}
                 onClick={handleCloseNavMenu}
                 sx={{ my: 2, color: 'white', display: 'block' }}
                 className="navbarSubpageLinkButton"
               >
                 {page}
               </Button>
               </a>
            ))}
            </div> */}
          {/* </Box>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } ,justifyContent: 'end' ,width:'100%' }}> */}

          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default SloNavbar;