import { useState } from 'react';
import '../styles/readMore.css';


// Createa reusable Read More/Less component
const ExpandableText = ({ children, descriptionLength, title, htmlType, html, htmlText, arrIndex }) => {
  const fullText = children;
  const titleText = title;
  var arr = null;
  var arrObj = null;
  if((htmlType === "li" || htmlType === "a") && Array.isArray(htmlText)===true){
    arr = htmlText[arrIndex];
  }
  else if(htmlType === "a"){
    arrObj = htmlText;
  }

  const [isExpanded, setIsExpanded] = useState(false);

  // This function is called when the read more/less button is clicked
  const toggleText = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <span className='text'>
        <span className="readMoreHeader">
          <span onClick={toggleText} className='toggle-button'>
        {isExpanded ? '+' : '+'}
        </span>
        <h1 className='title'>{titleText}</h1>
        <br />
        {htmlType ==="li"?
        <span className='NewLineText'>
        <ul className="nasvetiCardUl">
        {isExpanded ? arr.map((item, i) => {
            return <li key={i}>{item}</li>
        }) : ""
        }
        </ul></span>
              :
        htmlType ==="p"?
        <span className='NewLineText'>{children? isExpanded ? fullText : `${fullText.slice(0, descriptionLength)}` : ""}</span>
        :
        arrObj!== null && htmlType ==="a"?
        <span className='NewLineText'>{isExpanded ? 
          Object.keys(arrObj).map((keyName, i) => (
            <><a href = {arrObj[keyName]} target = "_blank" rel="noreferrer">{keyName}</a>, </>
        ))
       : ""
      }</span> :""
        }
      </span>
    </span>
  );
};

export default ExpandableText