import '../styles/warehousing.css';
import WarehousingNavbar from '../components/navbars/WarehousingNavbar';
import ExpandableText from './ReadMore';
import * as React from 'react';
import emailjs from '@emailjs/browser';
import { useTranslation} from 'react-i18next';
import Footer from './Footer'

function Warehousing() {

    const form1 = React.useRef();
    const { t, i18n } = useTranslation();
    //i18n.changeLanguage("en");

    const sendEmail = (e) => {
        console.log(process.env.REACT_APP_TITLE);
        e.preventDefault();
        emailjs
          .sendForm(
            process.env.REACT_APP_SERVICE_ID,
            process.env.REACT_APP_TEMPLATE_ID,
            form1.current,
            process.env.REACT_APP_PUBLIC_KEY
          )
          .then(
            (result) => {
              alert('message sent successfully...');
              console.log(result.text);
            },
            (error) => {
              console.log(error.text);
            }
          );
      };
  return (
    <div className="SloveniaContainer">
    <WarehousingNavbar />
    <div className='heroPage'>
        <div className="underHeaderHeroTextWarehousing"><h3>{t('WarehouseHeroText1')}</h3>
        </div>
        <div className="contentContainer" id="warehousingContentBg">
          <div className="SloContentInnerContainer">
        <div className="skladiscenjeFirstTextContainer">
        <h1>{t('WarehouseHeroText2')}</h1>
        <ul>
        <li>
        {t('skladisce-li1')}<br />
        </li>
        <li>
        {t('skladisce-li2')}<br />
        </li>
        <li>
        {t('skladisce-li3')}<br />
        </li>
        <li>
        {t('skladisce-li4')}<br />
        </li>
        <li>
        {t('skladisce-li5')}<br />
        </li>
        <li>
        {t('skladisce-li6')}<br />
        </li>
        <li>
        {t('skladisce-li7')}<br />
        </li>
        <li>
        {t('skladisce-li8')}<br />
        </li>
        <li>
        {t('skladisce-li9')}<br />
        </li>
        </ul>

        </div>
        <div className="skladiscenjeSecondTextContainer">
        <h1>{t('WarehouseHeroText3')}</h1>
        <div className='container'>
        <div className='card'>
            {/* Only show 120 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('skladisce-ddtitle1')} htmlType="p">
            {t('skladisce-dd1')}
            </ExpandableText>
        </div><br />
        <div className='card'>
            {/* Only show 120 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('skladisce-ddtitle2')} htmlType="p">
            {t('skladisce-dd2')}
            </ExpandableText>
        </div><br />
        <div className='card'>
            {/* Only show 120 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('skladisce-ddtitle3')} htmlType="p">
            {t('skladisce-dd3')}
            </ExpandableText>
        </div><br />
        <div className='card'>
            {/* Only show 120 characters in the beginning */}
            <ExpandableText descriptionLength={0} title={t('skladisce-ddtitle4')} htmlType="p">
            {t('skladisce-dd4')}
            </ExpandableText>
        </div><br /></div>
        </div>
        <div className="inquiryPage">
        <div className="inquiryFormContainer">
        <div className="inquiryText">
              <h1>{t('homePovpraševanje1')}</h1>
            </div>
        <div className="inquiryTitle">
          <h3>{t('homePovpraševanje3')}</h3>
        </div>
          <div className="inquiryFormTitle">
            <h2>{t('homeServicesMainTitle2')}</h2>
          </div>
          <div className="inquiryFormInnerContainer">

            <form onSubmit={sendEmail} ref={form1}>
            <span className="radioButtons">
              <span className="radioButtonsLeft">
                <span>
              <input type="radio" id="selitvenaEmbalaza" name="radio" defaultValue={t('inquiryGlobal2')} />
              <label htmlFor="selitvenaEmbalaza">{t('inquiryGlobal2')}</label>
                </span>
              </span>
              <span className="radioButtonsRight">
                <span>
              <input type="radio" id="skladisce" name="radio" defaultValue={t('inquiryGlobal4')} />
              <label htmlFor="skladisce">{t('inquiryGlobal4')}</label> 
              </span>
              </span>
            </span>
            <span className="lowerHalfOfForm">
              <span className="leftForm">
              <input type="text" id="imePriimek" name="imePriimek" placeholder={t('inquiryGlobal7')} /><br />
              <input type="text" id="email" name="email" placeholder={t('inquiryGlobal8')}/><br />
              <span className="inLineInput">
              <input type="text" id="selimIz" name="selimIz" placeholder={t('inquiryGlobal9')}/>
              <input type="text" id="selimV" name="selimV" placeholder={t('inquiryGlobal10')}/><br />
              </span>
              <input type="text" id="telefon" name="telefon" placeholder={t('inquiryGlobal11')}/><br />
            </span>
            <span className="rightForm">
              <textarea placeholder={t('inquiryGlobal12')} maxLength='1000' minLength='100' rows="16" cols="89">
                
              </textarea>
            </span>
              
            </span>
            <span className="submits">
            <input type="submit" value={t('inquiryGlobal13')} id="firstSubmit" />
            </span>

            </form>

          </div>
        </div>
      </div>
      </div>
      </div>
    </div>
    <Footer/>
    </div>
  );
}

export default Warehousing;
