import * as React from 'react';
import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import { useTranslation, Trans } from 'react-i18next';


const lngs = {
  en: { nativeName: 'English' },
  slo: { nativeName: 'Slovenian' }
};


const App = () => {

   const { t, i18n } = useTranslation();
   const location = useLocation();
   useEffect(() => {
           window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'instant'
  });
   }, [location]);

  return 
  <>

  </>
};


export default App;
