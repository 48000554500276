import * as React from 'react';
import '../styles/Footer.css';
import { useTranslation, Trans } from 'react-i18next';
import eng from '../assets/icons/eng.svg';
import slo from '../assets/icons/slo.svg';

function Footer() {
  const { t, i18n } = useTranslation();
  //i18n.changeLanguage('en-US');

  return (
    <section id="footer">
        <div id="footerFirst">
        <p>© 2024 | Vatovec d.o.o.</p>
        <a>Družbena odgovornost</a>

        </div>
        <div id="footerSecond">
        <a>Varovanje zasebnosti</a>
        <div className="languages">
           <img src={slo} className="navbarSloIcon"alt="Slovenian translations" onClick={() => i18n.changeLanguage('slo')}/>
               <img src={eng} className="navbarEngUsIcon"alt="English translations" onClick={() => i18n.changeLanguage("en")}/>
          {/* {Object.keys(lngs).map((lng) => (
            <>
               <img src={slo} className="navbarCallUsIcon"alt="Slovenian translations" onClick={() => i18n.changeLanguage("Slovenian")}/>
               <img src={eng} className="navbarCallUsIcon"alt="English translations" onClick={() => i18n.changeLanguage("English")}/>
            <button key={lng} style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }} type="submit" onClick={() => i18n.changeLanguage(lng)}>
              {lngs[lng].nativeName}
            </button>
            </>
          ))} */}
        </div>
        </div>
    </section>
  );
}
export default Footer;