import '../styles/App.css';
import ResponsiveAppBar from './Navbar';
import * as React from 'react';
import heroImgMobile from '../assets/vatovec-mobile.png'
import heroImg from '../assets/vatovec1.png'
import inquryImg from '../assets//icons/1.svg'
import { useTranslation} from 'react-i18next';
import homeImg from '../assets/icons/4.svg'
import contactIcon1 from '../assets/icons/2.svg'
import contactIcon2 from '../assets/icons/8.svg'
import contactIcon3 from '../assets/icons/5.svg'
import contactIcon4 from '../assets/icons/6.svg'
import contactIcon5 from '../assets/icons/7.svg'
import { useEffect, useState } from 'react';
import Footer from './Footer'
import { 
  BrowserRouter as Router,
  Switch,
  Routes,
  Route,
  Link
} from "react-router-dom";
import emailjs from '@emailjs/browser';




function Home() {

  const livingRoomItems = [
    { name: "ARM CHAIR", quantity: 0 },
    { name: "BABY GRAND PIANO", quantity: 0 },
    { name: "BOOKSHELF", quantity: 0 },
    { name: "BOOKSHELF (LIGHT)", quantity: 0 },
    { name: "ENTERTAINMENT", quantity: 0 },
  ];
  
  const bedroomItems = [
    { name: "LAMP", quantity: 0 },
    { name: "LAZY BOX CHAIR", quantity: 0 },
    { name: "CHAIR", quantity: 0 },
    { name: "CHAIR ARM", quantity: 0 },
    { name: "CHAIR THIN", quantity: 0 },
  ];

  const [selectedTab, setSelectedTab] = useState("LIVING_ROOM");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [livingRoomQuantities, setLivingRoomQuantities] = useState(livingRoomItems);
  const [bedroomQuantities, setBedroomQuantities] = useState(bedroomItems);

  const openInvetoryModal = () => {
    setIsModalOpen(true);
  };

  const closeInvetoryModal = () => {
    setIsModalOpen(false);
    // Combine the items from both tabs and filter out those with quantities greater than 0
    const selectedLivingRoomItems = livingRoomQuantities.filter(item => item.quantity > 0);
    const selectedBedroomItems = bedroomQuantities.filter(item => item.quantity > 0);

    // Merge both selected items and update the state
    const combinedSelectedItems = [...selectedLivingRoomItems, ...selectedBedroomItems];
    setSelectedItems(combinedSelectedItems);
  };
  const switchTab = (tab) => {
    setSelectedTab(tab);
  };

  const { t, i18n } = useTranslation();
  const form = React.useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    console.log("form current", form.current)
    form.current.furnishingItems = livingRoomItems;

    
    const serializedItems = selectedItems
    .map(item => `${item.name}: ${item.quantity}`)
    .join(', ');

  // Create a hidden input to store the serialized selected items
  const selectedItemsInput = document.createElement("input");
  selectedItemsInput.setAttribute("type", "hidden");
  selectedItemsInput.setAttribute("name", "selected_items");
  selectedItemsInput.setAttribute("value", serializedItems);

  // Append the hidden input to the form
  form.current.appendChild(selectedItemsInput);
  
  emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          alert('message sent successfully...');
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  const handleQuantityChange = (index, isIncrement, tab) => {
    const setQuantities = tab === "LIVING_ROOM" ? setLivingRoomQuantities : setBedroomQuantities;
    const quantities = tab === "LIVING_ROOM" ? [...livingRoomQuantities] : [...bedroomQuantities];
    const currentQuantity = quantities[index].quantity;
    if (isIncrement) {
      quantities[index].quantity = currentQuantity + 1;
    } else if (currentQuantity > 0) {
      quantities[index].quantity = currentQuantity - 1;
    }
    setQuantities(quantities);
  };
  const handleInputBlur = (index, tab) => {
    const setQuantities = tab === "LIVING_ROOM" ? setLivingRoomQuantities : setBedroomQuantities;
    const quantities = tab === "LIVING_ROOM" ? [...livingRoomQuantities] : [...bedroomQuantities];

    // Reset to 0 if the value is empty or invalid
    if (quantities[index].quantity === '' || isNaN(quantities[index].quantity)) {
      quantities[index].quantity = 0;
    }

    setQuantities(quantities);
  };
  const handleManualInputChange = (index, event, tab) => {
    const setQuantities = tab === "LIVING_ROOM" ? setLivingRoomQuantities : setBedroomQuantities;
    const quantities = tab === "LIVING_ROOM" ? [...livingRoomQuantities] : [...bedroomQuantities];

    const value = event.target.value;

    // Allow the input field to accept the value being typed, including blank
    quantities[index].quantity = value === '' ? '' : parseInt(value, 10);
    setQuantities(quantities);
  };
  const renderTableContent = () => {
    const items = selectedTab === "LIVING_ROOM" ? livingRoomQuantities : bedroomQuantities;

    return (
      <table className="furniture-table">
        <thead>
          <tr>
            <th>Item</th>
            <th className="selectionModalHeader">Selection</th>
          </tr>
        </thead>
        <tbody>
          {items.map((item, index) => (
            <tr key={index}>
              <td>{item.name}</td>
              <td>
                <div className="quantity-control">
                  <button
                    type="button"
                    onClick={() => handleQuantityChange(index, false, selectedTab)}
                    disabled={item.quantity === 0} className="quantity-btn minus"
                  >
                    -
                  </button>
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleManualInputChange(index, e, selectedTab)}
                    onBlur={() => handleInputBlur(index, selectedTab)}
                  />
                  <button type="button" onClick={() => handleQuantityChange(index, true, selectedTab)} className="quantity-btn plus">+</button>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };
  const handleOverlayClick = (e) => {
    if (e.target.classList.contains("modal-overlay")) {
      closeInvetoryModal();
    }
  };
  return (
    <div className="App">
      <ResponsiveAppBar />
      <div className='heroPage'>
        <div className="underHeaderHeroText"><h3>{t('home-subtitle1')}<br /> {t('home-subtitle2')}<br /> {t('home-subtitle3')}</h3>
        </div>
        <div className="heroImage">
        <img src={heroImgMobile} className="HeroImg heroImgPhone" alt="hero image" />
        <img src={heroImg} className="HeroImg heroImgPc" alt="hero image" />
        </div>
        <div className="serviceContainer" id="services">
          <div className="servicesCenteredContainer">
          <Link to="/slovenia" className="firstLink">
            <div className="service" id="service1">
              <div className="serviceTitle"><h3>{t('home-servicesTitle1')}</h3></div>
              <div className="serviceImg"></div>
              <div className="serviceUnderImageText">{t('home-servicesSubTitle1')}</div>
            </div>
            </Link>
            <Link to="/abroad" className="firstLink">
            <div className="service" id="service2">
              <div className="serviceTitle"><h3>{t('home-servicesTitle2')}</h3></div>
              <div className="serviceImg"></div>
              <div className="serviceUnderImageText">{t('home-servicesSubTitle2')}</div>
            </div>
            </Link>
            <Link to="/other" className="firstLink">
            <div className="service" id="service3">
              <div className="serviceTitle"><h3>{t('home-servicesTitle3')}</h3></div>
              <div className="serviceImg"></div>
              <div className="serviceUnderImageText">{t('home-servicesSubTitle3')}</div>
            </div>
            </Link>
            <Link to="/warehousing" className="firstLink">
            <div className="service" id="service4">
              <div className="serviceTitle"><h3>{t('home-servicesTitle4')}</h3></div>
              <div className="serviceImg"></div>
              <div className="serviceUnderImageText">{t('home-servicesSubTitle4')}</div>
            </div>
            </Link>
          </div>
          
          <div className="servicesCenteredBottomContainer">
            <div className="secondServicesTitle"><h1>{t('homeServicesMainTitle')}</h1></div>
            <div className="servicesSecondContainer">
            <Link to="/#" className="firstLink">
            <div className="service" id="service5">
              <div className="serviceTitle"><h3>{t('home-servicesTitle5')}</h3></div>
              <div className="serviceImg"></div>
              <div className="serviceUnderImageText">{t('home-servicesSubTitle5')}</div>
            </div>
            </Link>
            <Link to="/#" className="firstLink">
            <div className="service" id="service6">
              <div className="serviceTitle"><h3>{t('home-servicesTitle6')}</h3></div>
              <div className="serviceImg"></div>
              <div className="serviceUnderImageText">{t('home-servicesSubTitle6')}</div>
            </div>
            </Link>
            <Link to="/#" className="firstLink">
            <div className="service" id="service7">
              <div className="serviceTitle"><h3>{t('home-servicesTitle7')}</h3></div>
              <div className="serviceImg"></div>
              <div className="serviceUnderImageText">{t('home-servicesSubTitle7')}</div>
            </div>
            </Link>
            </div>
          </div>

        </div>
        
        <div className="sendInquiry" id="about">
        <div className="sendInquiryInnerContainer">
            <div className="inquiryImage">

            </div>
            <div className="inquiryText">
               <span className="inquiryIconCont"><img src={inquryImg} className="inquryIcon" alt="Send an inqury" /></span>
               <span className="inquryParagraph">
              <h2>{t('homePovpraševanje1')}</h2>
              <p>{t('homePovpraševanje2')}</p>
              </span>
            </div>
        </div>
        </div>
      </div>
      <div className="inquiryPage">
        <div className="inquiryPageInnerContainer">
        <div className="inquiryTitle">
          <h3>{t('homePovpraševanje3')}</h3>
        </div>
        <div className="inquiryFormContainer">
          <div className="inquiryFormTitle">
            <h1>{t('homeServicesMainTitle2')}</h1>
          </div>
          <div className="inquiryFormInnerContainer">
          <form onSubmit={sendEmail} ref={form}>
          <span className="radioButtons">
              <span className="radioButtonsLeft">
                <span>
              <input type="radio" id="selitvePoSloveniji" name="radio" defaultValue={t('inquiryGlobal1')} />
              <label htmlFor="selitvePoSloveniji">{t('inquiryGlobal1')}</label><br />
                </span>
                <span>
              <input type="radio" id="selitvenaEmbalaza" name="radio" defaultValue={t('inquiryGlobal2')} />
              <label htmlFor="selitvenaEmbalaza">{t('inquiryGlobal2')}</label>
                </span>
              </span>
              <span className="radioButtonsRight">
                <span>
                   <input type="radio" id="mednarodneSelitve" name="radio" defaultValue={t('inquiryGlobal3')} />
                   <label htmlFor="mednarodneSelitve">{t('inquiryGlobal3')}</label><br />
                </span>
                <span>
              <input type="radio" id="skladisce" name="radio" defaultValue={t('inquiryGlobal4')} />
              <label htmlFor="skladisce">{t('inquiryGlobal4')}</label> 
              </span>
              </span>
            </span>
            {/* Modal */}
{isModalOpen && (
        <div className="modal-overlay" onClick={handleOverlayClick}>
          <div className="modal">
            <div className="modal-header">
              <h2>Inventory Details</h2>
              <button className="close-button" type="button" onClick={closeInvetoryModal}>
                &times;
              </button>
            </div>
            <div className="tabs">
              <button
                type="button"
                className={selectedTab === "LIVING_ROOM" ? "active-tab" : ""}
                onClick={() => switchTab("LIVING_ROOM")}
              >
                Living Room
              </button>
              <button
                type="button"
                className={selectedTab === "BEDROOM" ? "active-tab" : ""}
                onClick={() => switchTab("BEDROOM")}
              >
                Bedroom
              </button>
            </div>
              {renderTableContent()}
              
             <footer className="summary">
             <button type="button" onClick={closeInvetoryModal} className="submit-button">Confirm Selection</button>
            {/*  <h2>Summary</h2>
              <table className="summary-table">
                <thead>
                  <tr>
                    <th>Room</th>
                    <th>Weight</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                </tbody>
              </table>
              <div className="total">
                <p>Total: 130KG, 78€</p>
              </div>
              */}
            </footer>
          </div>
        </div> 
      )}
            <span className="lowerHalfOfForm">
              <span className="leftForm">
              <input type="text" id="imePriimek" name="imePriimek" placeholder={t('inquiryGlobal7')} /><br />
              <input type="text" id="email" name="email" placeholder={t('inquiryGlobal8')}/><br />
              <span className="inLineInput">
              <input type="text" id="selimIz" name="selimIz" placeholder={t('inquiryGlobal9')}/>
              <input type="text" id="selimV" name="selimV" placeholder={t('inquiryGlobal10')}/><br />
              </span>
              <span className="selectContainer">
              {/* <select name="roomQuantity" className="formDropdown">
                <option value="" disabled selected>Koliko sob selite</option>
                <option value="1">1 soba</option>
                <option value="2">2 sobi</option>
                <option value="3">3 sobe</option>
                <option value="4">4 sobe</option>
              </select>
              <select name="furnishing" className="formDropdown">
                <option value="" disabled selected>koliko so opremljene sobe</option>
                <option value="1">malo opremljene sobe</option>
                <option value="2">srednje opremljene sobe</option>
                <option value="3">bolj opremljene sobe</option>
              </select> */}
              <button type="button" className="inventory-button" onClick={openInvetoryModal}>Open Inventory</button>
                      {/* Selected Items */}
        {selectedItems.length > 0 && (
          <div className="selected-items">
            <h3>Selected Items:</h3>
            <ul>
              {selectedItems.map((item, index) => (
                <li key={index}>
                  {item.name} - {item.quantity}
                </li>
              ))}
            </ul>
          </div>
        )}
              </span>
              <input type="text" id="telefon" name="telefon" placeholder={t('inquiryGlobal11')}/><br />
            </span>
            <span className="rightForm">
              <textarea placeholder={t('inquiryGlobal12')} name="povprasevanjeTxt" maxLength='1000' minLength='20' rows="16" cols="89">
                
              </textarea>
            </span>
              
            </span>
            <span className="submits">
            <input type="submit" value={t('inquiryGlobal13')} id="firstSubmit" />
            {/* <h2>{t('inquiryGlobal5')}</h2> */}
            {/* <div id="secondSubmit"><h3>{t('inquiryGlobal6')}</h3></div> */}
            </span>

            </form>

      


          </div>
        </div>
        </div>
        <div className="sendInquiry">
        <div className="sendInquiryInnerContainer">
        <div className="inquiryImage">

        </div>
      <div className="inquiryText">
        <span className="inquiryIconCont"><img src={homeImg} className="inquryIcon" alt="Send an inqury" /></span>
          <span className="inquryParagraph">
        <h2>{t('homePovpraševanje4')}</h2>
        <p>{t('homePovpraševanje5')}</p>
      </span>
</div>
        </div>
        </div>
        
      </div>
      {/* <div className="aboutUsContainer" id="about">
        <div className="aboutUsTitle">
          <h3>Z veseljem bomo pregledali vaše želje in se prilagodili vsem vašim potrebam.</h3>
        </div>
        <div className="aboutUsInnerContainer">
          <div className="aboutUsCategory">
            <div className="categoryTitle">
              <h2>O PODJETJU</h2>
            </div>
            <div className="categoryText">
              <p>Lorem ipsum dolore</p>
            </div>
            <div className="categoryButton">
              <p>VEČ</p>
            </div>
          </div>
          <div className="aboutUsCategory">
            <div className="categoryTitle">
              <h2>O PODJETJU</h2>
            </div>
            <div className="categoryText">
              <p>Lorem ipsum dolore</p>
            </div>
            <div className="categoryButton">
              <p>VEČ</p>
            </div>
          </div>
          <div className="aboutUsCategory">
            <div className="categoryTitle">
              <h2>O PODJETJU</h2>
            </div>
            <div className="categoryText">
              <p>Lorem ipsum dolore</p>
            </div>
            <div className="categoryButton">
              <p>VEČ</p>
            </div>
          </div>

        </div>
        <div className="sendInquiry">
        <div className="sendInquiryInnerContainer">
            <div className="inquiryImage">
            </div>
            <div className="inquiryText">
              <h2>Lokacija in kontakt</h2>
              <p>Veseli bomo vašega kontakta in povpraševanja</p>
            </div>
        </div>
        </div>
      </div> */}
      <div className="contactContainer" id="contact">
      
      <div className="contactTitle">
          <h3>{t('homePovpraševanje6')}</h3>
        </div>
        <div className="aboveMapContainer">
        <div className="centeredAboveMapContainer">
          <div className="aboveContact">
            <div className="aboveContactImg">
            </div>
            <div className="aboveContactText">
            <span className="aboveContactTextIcon">
              <img src={contactIcon1} className="contactIcon" alt="Call us" />
              </span>
              <span>
              <h2>{t('homeContactTitle1')}</h2>
              <h3>{t('homeContactSubTitle1')}</h3>
              </span>
            </div>
          </div>
          <div className="aboveContact">
            <div className="aboveContactImg">
            </div>
            <div className="aboveContactText">
            <span className="aboveContactTextIcon">
              <img src={contactIcon2} className="contactIcon" alt="Write us" />
              </span>
              <span>
              <h2>{t('homeContactTitle2')}</h2>
              <h3>{t('homeContactSubTitle2')}</h3>
              </span>
            </div>
          </div>
          <div className="aboveContact">
            <div className="aboveContactImg">
            </div>
            <div className="aboveContactText">
              <span className="aboveContactTextIcon">
              <img src={contactIcon3} className="contactIcon" alt="Visit us" />
              </span>
              <span>
              <h2>{t('homeContactTitle3')}</h2>
              <h3>{t('homeContactSubTitle3')}</h3>
              </span>
            </div>
          </div>
        </div>
        </div>
        <div className="mapContainer">
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2768.7864700731943!2d14.503794996186853!3d46.05535721926459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4765329daf0feb15%3A0x6c0c0f8a34b63f99!2s%C4%8Cufarjeva%20ulica%205%2C%201000%20Ljubljana!5e0!3m2!1ssl!2ssi!4v1688600284553!5m2!1ssl!2ssi" width="100%" height="80%" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <div className="bellowMapContainer">
        <div className="centeredBellowMapContainer">
          <div className="bellowContact">
            <div className="bellowContactImg">
            </div>
            <div className="bellowContactText">
            <span className="bellowContactTextIcon">
              <img src={contactIcon4} className="contactIcon" alt="Office space" />
              </span>
              <span className="bellowContactTextText">
              <h2>{t('homeContactTitle4')}</h2>
              <h3>{t('homeContactSubTitle4')}</h3>
              </span>
            </div>
          </div>
          <div className="bellowContact">
            <div className="bellowContactImg">
            </div>
            <div className="bellowContactText">
            <span className="bellowContactTextIcon">
              <img src={contactIcon5} className="contactIcon" alt="Warehouse space" />
              </span>
              <span className="bellowContactTextText">
              <h2>{t('homeContactTitle5')}</h2>
              <h3>{t('homeContactSubTitle5')}</h3>
              </span>
            </div>
          </div>
        </div>
          </div>
      </div>
      <Footer/>
    </div>
  );
}

export default Home;
