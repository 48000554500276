import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n';
import Home from './components/Home'
import Slovenia from './components/Slovenia'
import Abroad from './components/Abroad'
import Warehousing from './components/Warehousing'
import Other from './components/Other'
import {
  createBrowserRouter,
  RouterProvider
} from "react-router-dom";

const router = createBrowserRouter([
  {
    path: '/',
    element: <Home />,
  },
  {
    path: '/slovenia',
    element: <Slovenia />,
  },

  {
    path: '/abroad',
    element: <Abroad />,
  },
  {
    path: '/warehousing',
    element: <Warehousing />,
  },
  {
    path: '/other',
    element: <Other />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} >
    <App />
    </RouterProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
